<template>
  <div class="form">
    <div class="nav">
      发布梦想之旅
    </div>
    <div class="input">
      <van-field v-model="items.name" placeholder="请输入您的姓名" />
      <van-field v-model="items.phone" placeholder="请输入您的手机号码" />
    </div>
    <div class="content">
      <div class="title">
        编辑众筹宣言
      </div>
      <van-field class="textarea" v-model="items.declaration" type="textarea" placeholder="请输入您的众筹宣言" />
    </div>

    <div class="color">
      <span class="name">
        风格
      </span>
      <ul>
        <li
          @click="num = index"
          v-for="(color, index) in styleList"
          :key="index"
          :class="{'active': num === index}"
          :style="{'backgroundColor': color}"
        ></li>
      </ul>
    </div>

    <div class="text" @click="onSubInfo">
      发布梦想之旅
    </div>

  </div>
</template>

<script>
import axios from '@/config/axios'

export default {
  name: "formPage",
  data() {
    return {
      styleList: [
        '#0199CA',
        '#326696',
        '#029688',
        '#FD9800',
        '#98669B',
        '#FF9899',
        '#E8473F',
        '#989898',
        '#9A9A66'
      ],
      items: {
        name: '',
        phone: '',
        declaration: '朋友就是要互相支持，今天我帮你，明天你帮我！拼人品的时候到了！旅行，力挺你的壮举！',
        colour: '',
        uid: 3
      },
      num: 0,
      sharePopup: false,
      shareUrl: '无链接'
    }
  },
  created() {
    this.init ()
  },
  methods: {
    init () {
      let data = window.localStorage.getItem('user') || {'demo': '123456'}
      data = JSON.parse(data)
      if (!data.nickname) return false
      let { nickname } = data
      this.items.name = nickname
    },
    onSubInfo () {
      let test = /^[1][3-9][0-9]{9}$/
      if (!this.items.name) {
        this.$toast('请填写正确的手机号码')
        return
      }
      if (!test.test(this.items.phone)) {
        this.$toast('请填写正确的手机号码')
        return
      }
      if (!this.items.declaration) {
        this.$toast('请填写您的众筹宣言')
        return
      }
      let _this = this
      this.$dialog.confirm({
        title: '购买提示',
        message: '发布成功后此手机号将于当前使用微信绑定，是否继续发布？'
      }).then(() => {
        _this.onSub()
      }).catch(() => {
        console.log('发布取消')
      })
    },
    onSub () {
      this.items.colour = this.styleList[this.num]
      axios.post('binding', this.items)
          .then(res => {
            if (res.code === -3) {
              this.$dialog.alert({
                title: '提示',
                message: res.message
              }).then(() => {
                this.$router.push({
                  name: 'index'
                })
              })
              return
            }

            if(res.code === -1) {
              this.$dialog.alert({
                title: '提示',
                message: res.message
              }).then(() => {
                console.log('点击了确认')
                window.localStorage.removeItem('code')
                window.localStorage.removeItem('token')
                window.localStorage.removeItem('user')
                this.$router.push({
                  name: 'index'
                })
              })
              return
            }
            this.$toast(res.message)
            this.$router.replace(res.url)
          })
    }
  }
};
</script>

<style scoped lang="scss">
.form {
  padding-bottom: 98px;
  .nav {
    text-align: center;
    line-height: 80px;
    font-size: 28px;
    background: #ff4400;
    color: #ffffff;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 99;
  }
  .input {
    padding-top: 15px;
    /deep/ {
    .van-field__control{
	font-size:.3rem;
    }
    }
  }
  .content {
    padding-top: 20px;
    .title {
      padding: 0 30px;
      font-size: 26px;
      line-height: 80px;
      color: #666;
    }
    .textarea {
      /deep/ {
        .van-field__control {
          min-height: 300px;
	  font-size:.3rem;
	  line-height:.4rem;
        }
      }
    }
  }
  .color {
    display: flex;
    padding: 0 30px;
    align-items: center;
    height: 120px;
    margin-top: 20px;
    background: #ffffff;
    .name {
      font-size: 32px;
      padding: 0 30px 0 0;
    }
    ul {
      flex: 1 0 0;
      align-items: center;
      justify-content: space-between;
      li {
      	float:left;
        width: 50px;
        height: 50px;
        position: relative;
        &:after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 20px;
          height: 20px;
          border-radius: 15px;
        }
        &.active {
          &:after {
            background: #ffffff;
          }
        }
      }
    }
  }
}
.text {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #ff4400;
  text-align: center;
  color: #ffffff;
  font-size: 42px;
  line-height: 100px;
  &:active {
    opacity: 0.8;
  }
}

</style>

<style>
.van-dialog__message{
	font-size:.3rem!important;
	line-height:.4rem!important;
}
.van-dialog{
	font-size:.3rem!important;
}
</style>
